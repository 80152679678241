import React from "react"
import { Link } from "gatsby"

import globalData from "../../../content/settings/global.yml"
import image from "../../assets/images/cnr-logo.svg"
import metrologyImage from "../../assets/images/metrology-logo.svg"
import "./footer.scss"

const Footer = () => (
    <>
        <footer id="footer" className="footer">
            <div className="page-width">
                <div className="footer__inner">
                    <div className="footer__column">
                        <img src={image} alt="CNR Engineering Logo" />
                        <a href="https://cnrmetrology.co.uk/">
                            <img className="metrology-logo" src={metrologyImage} alt="CNR Metrology Logo" />
                        </a>
                    </div>
                    <div className="footer__column f_address">
                        <h6>Address</h6>
                        <p>CNR Engineering,</p>
                        <p>Skitham Lane,</p>
                        <p>Out Rawcliffe,</p>
                        <p>PR3 6BE</p>
                    </div>
                    <div className="footer__column f_contact">
                        <h6>CNR Engineering</h6>
                        <p><a href="mailto:richard@cnrengineering.co.uk"><svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ababab" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg> richard@cnrengineering.co.uk</a></p>
                        <p>
                            <a href={`tel: ${globalData.companyPhoneNumber ? globalData.companyPhoneNumber : ""}`}>
                                <svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="#ababab" d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"></path></svg>
                                {globalData.companyPhoneNumber ? globalData.companyPhoneNumber : ""}
                            </a>
                        </p>
                        <p>
                            <a href="tel:07860536741">
                                <svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="#ababab" d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path></svg>
                                {globalData.richardPhoneNumber ? globalData.richardPhoneNumber : ""}
                            </a>
                        </p>
                    </div>
                    <div className="footer__column f_contact">
                        <h6>CNR Precision</h6>
                        <p><a href="mailto:paul@cnrengineering.co.uk"><svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ababab" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg> paul@cnrengineering.co.uk</a></p>
                        <p>
                            <a href={`tel: ${globalData.companyPhoneNumber ? globalData.companyPhoneNumber : ""}`}>
                                <svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="#ababab" d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"></path></svg>
                                {globalData.companyPhoneNumber ? globalData.companyPhoneNumber : ""}
                            </a>
                        </p>
                        <p>
                            <a href="tel:07860334105">
                                <svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="#ababab" d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path></svg>
                                {globalData.paulPhoneNumber ? globalData.paulPhoneNumber : ""}
                            </a>
                        </p>
                    </div>
                    <div className="footer__column f_more-info">
                        <h6>More Info</h6>
                        <ul>
                            <li>
                                <Link to="/services/">
                                    Services
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact/">
                                    Contact
                                </Link>
                            </li>
                            <li>
                                <Link to="/about/">
                                    About
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div className="footer__lower">
                    <p>
                        <span className="copyright">© {new Date().getFullYear()} CNR Engineering</span>
                        {/* <span className="pipe">|</span> */}
                        {/* <Link to="/privacy-policy/">Privacy Policy</Link> */}
                        {/* <span className="pipe">|</span> */}
                        {/* <Link to="/terms-and-conditions/">Terms & Conditions</Link> */}
                    </p>
                </div>
            </div>
        </footer>
    </>
)

export default Footer
