import { Link } from "gatsby"
import React from "react"
import image from "../../assets/images/wagon.jpg"

const DesktopNav = ({ handleMouseEnter, handleMouseLeave, fleet, serviceEngineering, servicePrecision }) => (
    <nav
        className="header__navigation"
        role="navigation"
        aria-label="Primary navigation"
    >
        <ul className="header__navigation-list">
            <li className="header__navigation-item">
                <Link
                    activeClassName="active-link"
                    to="/"
                >
                    Home
                    <span></span>
                </Link>
            </li>
            <li className="header__navigation-item dropdown" onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
                <a href="/services/">
                    Services
                    <span></span>
                </a>
                <div className="dropdown__content">
                    <div className="page-width">
                        <div className="dropdown__content__container">
                            <div className="dropdown__column engineering_column">
                                <h5>CNR Engineering</h5>
                                <div>
                                    <ul className="service-engineering__list">
                                        {serviceEngineering &&
                                        serviceEngineering.map(({ node: serviceEngineering }) => (
                                            serviceEngineering.frontmatter.displayonsite &&
                                            <li key={serviceEngineering.id}>
                                                {
                                                    serviceEngineering.frontmatter.title === "Marked Structural Steel"
                                                    ?
                                                        <Link className="service-engineering__list-anchor" style={{paddingLeft: 30}} to={serviceEngineering.fields.slug}>
                                                            <svg style={{left: 0, position: "absolute", top: "-7px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 280 200" fillRule="evenodd" clipRule="evenodd"><path d="M110 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 000 140 70 70 0 0010-.5zM280 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 00-68.38 55H260v30h-58.38A70 70 0 00270 170a70 70 0 0010-.5z"/></svg>{serviceEngineering.frontmatter.title}
                                                        </Link>
                                                    :
                                                        <Link className="service-engineering__list-anchor" to={serviceEngineering.fields.slug}>
                                                            {serviceEngineering.frontmatter.title}
                                                        </Link>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="dropdown__column precision_column">
                                    <h5>CNR Precision</h5>
                                    <ul>
                                    {servicePrecision &&
                                        servicePrecision.map(({ node: servicePrecision }) => (
                                            servicePrecision.frontmatter.displayonsite &&
                                            <li key={servicePrecision.id}>
                                                <Link className="service-engineering__list-anchor" to={servicePrecision.fields.slug}>
                                                    {servicePrecision.frontmatter.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            <div className="dropdown__column precision_column">
                                <h5>Our Fleet</h5>
                                <Link to="/our-fleet/" className="dropdown__feature">
                                    <div className="dropdown__feature_img" style={{backgroundImage: `url('${image}')`}}>
                                        <span>View Fleet</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li className="header__navigation-item">
                <Link
                    activeClassName="active-link"
                    to="/our-fleet/"
                >
                    Our Fleet
                    <span></span>
                </Link>
            </li>
            <li className="header__navigation-item">
                <Link
                    activeClassName="active-link"
                    to="/workshop-and-equipment/"
                >
                    Workshop & Equipment
                    <span></span>
                </Link>
            </li>
            <li className="header__navigation-item">
                <Link
                    activeClassName="active-link"
                    to="/about/"
                >
                    About
                    <span></span>
                </Link>
            </li>
            <li className="header__navigation-item">
                <Link
                    activeClassName="active-link"
                    to="/contact/"
                >
                    Contact
                    <span></span>
                </Link>
            </li>
        </ul>
    </nav>
)

export default DesktopNav
