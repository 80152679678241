import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import DesktopNav from "../Navigation/desktopNav"
import MobileNav from "../Navigation/mobileNav"

import "./header.scss"

class Header extends React.Component {

  state = {
    showMenu: false,
    mobileMenuButtonUpdate: false,
    isSticky: false,
    isSolid: false
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
      mobileMenuButtonUpdate: !this.state.mobileMenuButtonUpdate
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState({
        isSticky: true
      });
    } else {
      this.setState({
        isSticky: false
      });
    }
  }

  handleMouseEnter = () => {
    this.setState({
      isSolid: true
    });
  }

  handleMouseLeave = () => {
    this.setState({
      isSolid: false
    });
  }

  render() {
    const { isHeaderSolid } = this.props;
    const { showMenu, mobileMenuButtonUpdate, isSticky, isSolid } = this.state;
    const mobileMenuShow = showMenu ? 'open-mobile-menu' : '';
    const mobileMenuButtonChange = mobileMenuButtonUpdate ? 'mobile-button-update' : '';
    const solidHeaderCname = isSticky || showMenu || isSolid || isHeaderSolid ? 'is-solid' : '';

    return (
      <StaticQuery
        query={graphql`
          query DesktopNavItemsQuery {
            fleet: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "fleet-child-page"}}}) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    templateKey
                    displayonsite
                  }
                }
              }
            }
            serviceEngineering: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "service-child-page"}, servicetype: {eq: "CNR Engineering"}}}, sort: {fields: frontmatter___sortorder}) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    templateKey
                    displayonsite
                  }
                }
              }
            }
            servicePrecision: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "service-child-page"}, servicetype: {eq: "CNR Precision"}}}) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    templateKey
                    displayonsite
                  }
                }
              }
            }
          }
        `}
        render={data => {
        const { edges: fleet } = data.fleet
        const { edges: serviceEngineering } = data.serviceEngineering
        const { edges: servicePrecision } = data.servicePrecision
          return (
            <header className={`${solidHeaderCname}`} >
              <div className="header__container">
                  <Link
                    to="/"
                    className="header__logo"
                  >
                  <svg width="86px" height="48px" version="1.1" className="cnr-logo-svg" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 194.665 109" enableBackground="new 0 0 194.665 109">
                    <g className="logo-black">
                      <polygon fill="#6b6b6b" points="70.014,15.065 72.917,16.676 71.75,20.166 68.667,20.166 68.667,16.166 	"/>
                      <path fill="#6b6b6b" d="M28.292,79.478l-1,2.75c0,0,7.875,0.438,8.313,0s2.688-5.65,7.313-6.513s-3.063-2.987-3.063-2.987
                        L28.292,79.478z"/>
                      <path fill="#6b6b6b" d="M52.5,4.478c0.354,0,2.979,0.938,2.979,0.938s0,5.875,2.063,8.188s-3.625,0.563-3.625,0.563l-3.438-3.875"
                        />
                      <path fill="#6b6b6b" d="M71.917,63.708c0,0,0.333-8.171,5.75-7.315l4.917-0.894l1.25-4.666l-8.5-4.117c0,0-12.5,8.167-12.083,8.6
                        c0.321,0.333,2.545,6.062,4.041,9.515c0.448,1.034,1.876,0.252,1.876,0.252l1.125-0.625L71.917,63.708z"/>
                      <path fill="#6b6b6b" d="M23.25,53.332c0,0-12.417-14.667,2.5-28.75c0,0,8-9.25,22.167-5.333
                        C47.917,19.249,12.417,20.201,23.25,53.332z"/>
                      <polygon fill="#6b6b6b" points="3.667,35.166 3.667,36.166 6.604,38.041 	"/>
                      <path fill="#6b6b6b" d="M4.542,53.332l0.875,2.083c0,0,6.125,1.25,6.5,2.25C11.917,57.666,11.604,54.624,4.542,53.332z"/>
                      <path fill="#6b6b6b" d="M13.479,70.541c0,0,3.688,3.875,3.938,3.688s4.438-5.118,8.688-1.84
                        C26.104,72.388,23.167,66.541,13.479,70.541z"/>
                      <path fill="#6b6b6b" d="M79.25,45.749c0.817,0.117-5.039-6.384,4.272-9.942l-4.689-0.642l-3.417,2.875
                        C75.417,38.041,75.75,45.249,79.25,45.749z"/>
                    </g>
                    <g className="logo-cog">
                      <path fill="#ececec" d="M51.167,19.603c0,0-22.813-11.969-34.063,15.906c0,0-3.844,19.828,14.656,25.453
                        c0,0,0.141,7.414,6.266,12.039l-5.484,5.664h-4.875c0,0,2.375-16.5-13.25-9l-3.583-3.5c0,0,8.917-13.416-7.083-13.833l-3-4.476
                        c0,0,14.917-7.441,1.167-15.441l0.667-4.583c0,0,15.333,1.25,8.5-13.417l3.25-3.75c0,0,12.917,7.5,14.5-8.333l4.25-0.75
                        c0,0,7.25,14.333,16.333-0.417l4.417,2.25c0,0-1.083,15.917,14,7.417l4.083,3.833l-4.25,6.75l-3.833,3.833L51.167,19.603z"/>
                      <path fill="#ececec" d="M61.208,26.545c0,0,8.396,19.87-8.708,34.369c0.379-0.187,1.311,0.769,1.556,0.972
                        c3.134,2.598,6.874,3.282,10.827,3.368c1.154,0.025,3.802-0.261,3.414-2.04c-0.247-1.133-0.321-2.137-0.145-3.296
                        c0.201-1.322,0.498-2.701,1.182-3.865c0.757-1.29,1.972-2.354,3.345-2.943c2.04-0.876,4.37-0.625,6.514-0.39
                        c0.083,0.009,0.971,0.124,0.974,0.111C80.167,52.828,81,48.989,81,48.989c0,0-4.271-2.858-5.75-7.074
                        c-1.979-5.64-0.822-7.655-2.667-10.042C68.211,26.218,61.544,26.593,61.208,26.545z"/>
                    </g>
                    <g className="logo-cnr">
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M124.894,59.712c0.5-2.512,0.85-3.854,1.025-5.219
                        c1.413-11.019,2.86-22.034,4.144-33.067c0.435-3.742,3.059-3.354,5.514-3.348c5.111,0.014,5.931,0.844,5.232,5.819
                        c-2.237,15.968-4.615,31.917-6.838,47.888c-0.306,2.198-1.258,3.19-3.386,3.542c-6.324,1.044-9.141-0.417-11.675-6.417
                        c-3.999-9.468-7.93-18.963-11.923-28.434c-0.619-1.467-1.413-2.861-2.126-4.289c-0.428,0.019-0.856,0.037-1.284,0.056
                        c-0.531,2.969-1.192,5.922-1.569,8.91c-1.123,8.91-2.158,17.831-3.173,26.754c-0.273,2.406-1.907,3.879-4.564,3.359
                        c-0.968-0.19-1.311-0.226-2.305-0.307c-3.782-0.31-4.439-0.952-3.933-4.616c0.975-7.063,2.127-14.101,3.183-21.153
                        c1.251-8.358,2.458-16.723,3.729-25.078c0.348-2.284-0.589-5.171,3.103-5.883c4.527-0.872,8.989,0.235,10.518,3.441
                        c2.213,4.646,4.083,9.459,6.013,14.236c2.672,6.618,5.242,13.277,7.915,19.894C122.899,56.805,123.628,57.676,124.894,59.712z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M169.249,57.353c-1.148-2.921-3.393-2.726-5.758-2.608
                        c-2.637,0.131-2.482,2.117-2.743,3.947c-0.632,4.445-1.448,8.865-2.03,13.315c-0.289,2.205-1.157,3.414-3.516,3.119
                        c-0.166-0.021-0.332-0.055-0.497-0.054c-8.39,0.038-8.232,0.062-6.983-8.397c2.156-14.616,4.209-29.249,6.035-43.908
                        c0.389-3.121,1.348-4.474,4.535-4.381c7.627,0.222,15.299-0.161,22.878,0.527c8.405,0.763,13.027,6.58,12.593,15.066
                        c-0.461,9-4.037,16.058-13.225,19.145c-0.773,0.26-1.487,0.697-2.66,1.259c3.577,5.58,7.066,10.987,10.518,16.419
                        c1.86,2.927,1.631,3.862-1.769,3.963c-3.546,0.105-7.12-0.727-10.681-1.149C175.946,73.616,171.422,62.883,169.249,57.353z
                        M177.856,42.887c4.993-4.529,5.28-12.863,0.129-15.065c-3.193-1.366-7.043-1.309-10.621-1.574
                        c-0.732-0.054-2.121,1.203-2.269,2.023c-0.905,5.051-1.617,10.141-2.196,15.241c-0.105,0.927,0.424,2.459,1.136,2.855
                        c3.136,1.74,11.121-0.477,13.991-3.64L177.856,42.887z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M59.522,75.702c-0.033,0.001-0.065,0.002-0.098,0.003
                        c-0.967,0.028-1.929-0.011-2.895-0.011c-11.132-0.385-17.996-5.801-20.576-16.67c-2.266-9.55-1.076-18.812,3.495-27.52
                        c4.273-8.143,11.424-12.591,20.261-13.58c5.169-0.578,10.606,0.131,15.783,1.051c4.953,0.879,8.129,5.176,8.028,9.541
                        c-0.061,2.651-0.973,4.92-3.726,5.909c-2.693,0.967-4.266-0.294-5.637-2.586c-4.521-7.558-15.197-8.735-21.436-0.748
                        c-4.991,6.389-6.817,13.759-5.345,21.601c2.1,11.183,12.471,16.588,23.165,12.477c1.697-0.652,3.267-1.627,4.944-2.336
                        c2.427-1.026,3.514,0.098,3.416,2.483c-0.17,4.147-2.641,6.965-6.258,8.18c-1.32,0.444-2.689,0.748-4.054,1.021
                        c-2.353,0.471-4.719,0.884-7.112,1.075C60.826,75.644,60.174,75.682,59.522,75.702z"/>
                    </g>
                    <g className="logo-shadow">
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M62.456,77.575c-6.667,1.707-13.109,1.51-19.413-1.409
                        c-5.214-2.415-8.571-6.315-10.193-11.576c-3.045-9.879-2.553-19.666,1.762-29.155c0.235-0.517,0.63-0.961,1.502-2.261
                        c-0.958,5.305-2.052,9.597-2.448,13.953c-0.688,7.566,0.169,14.985,4.79,21.349c4.665,6.427,11.597,8.497,19.122,9.081
                        C59.194,77.682,60.829,77.575,62.456,77.575z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M93.913,20.278c-1.417,9.773-2.857,19.542-4.243,29.319
                        c-0.952,6.719-2.026,13.43-2.679,20.18c-0.58,5.989-0.227,6.16,5.587,6.727c0.606,0.059,1.201,0.224,2.081,0.394
                        c-0.541,0.401-0.747,0.684-0.983,0.711c-9.476,1.095-10.165,0.409-8.834-8.904c2.091-14.633,4.181-29.267,6.34-43.891
                        c0.243-1.646,0.934-3.226,1.417-4.836C93.037,20.078,93.475,20.178,93.913,20.278z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M105.094,39.695c3.091,7.436,6.18,14.872,9.274,22.306
                        c1.084,2.603,2.295,5.158,3.251,7.807c1.648,4.562,4.449,7.325,9.643,6.822c0.465-0.045,0.96,0.146,1.429,0.272
                        c0.137,0.037,0.231,0.229,0.345,0.351c-9.044,2.065-11.004,1.122-14.535-7.194c-3.242-7.636-6.522-15.258-9.549-22.979
                        c-0.866-2.209-0.908-4.742-1.328-7.126C104.115,39.867,104.604,39.781,105.094,39.695z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M154.222,77.793c-2.133,0-4.268,0.063-6.397-0.013
                        c-4.529-0.162-5.249-0.969-4.64-5.464c0.917-6.759,1.952-13.502,2.911-20.256c1.287-9.051,2.533-18.108,3.843-27.156
                        c0.194-1.346,0.646-2.655,0.979-3.981c0.344,0.051,0.688,0.102,1.031,0.153c0,1.877,0.217,3.784-0.034,5.626
                        c-2.02,14.788-4.148,29.562-6.189,44.347c-0.523,3.79,0.588,5.044,4.54,5.375c1.275,0.107,2.566,0.017,3.851,0.017
                        C154.151,76.893,154.186,77.343,154.222,77.793z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M175.536,74.662c0.176,0.183,1.309,0.751,1.548,0.887
                        c0,0,5.959,0.833,6.984,0.844c0.284,0.003,1.34-0.027,1.625-0.016c-0.05-0.002-0.541,0.232-0.695,0.286
                        c-2.228,0.636-4.372,0.914-6.727,0.792c-2.302-0.119-4.5-0.798-5.897-2.591c-0.787-1.009-1.404-2.137-2.002-3.265
                        c-0.076-0.142-0.162-0.282-0.227-0.429c-1.485-3.316-2.754-6.729-4.254-10.037c-0.694-1.532-1.706-2.919-2.561-4.352
                        c2.992-1.214,4.51,0.555,5.609,3.363c0.678,1.729,1.365,3.453,2.086,5.164c0.703,1.672,1.48,3.311,2.203,4.974
                        c0.52,1.192,1.005,2.405,1.678,3.521C175.098,74.122,175.294,74.412,175.536,74.662z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M128.938,20.201c-1.475,11.493-2.951,22.985-4.469,34.813
                        C122.18,51,125.311,24.024,128.938,20.201z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M58.044,27.587c0.616-0.07,1.261-0.524,1.85-0.721
                        c0.607-0.203,1.246-0.326,1.88-0.406c1.576-0.198,3.203-0.05,4.685,0.537c0.766,0.303,1.56,0.698,2.237,1.169
                        c1.176,0.817,2.322,1.717,3.254,2.812c3.009,3.536,1.822,2.136,2.799,3.604c0.873,1.311,3.503,0.836,2.395,1.604
                        c-0.916,0.635-2.654,0.404-3.206,0.112c-2.71-1.431-4.208-4.424-6.654-6.204c-1.224-0.891-2.7-1.455-4.146-1.872
                        c-0.847-0.244-1.711-0.426-2.577-0.591C59.717,27.47,58.895,27.49,58.044,27.587z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M177.958,41.111c1.63-9.708,0.767-10.518-9.167-12.458
                        c-0.43-0.084-1.596-0.169-2.679-0.719c1.804-0.139,2.885-0.292,3.966-0.295c9.72-0.025,12.434,4.378,8.283,13.37
                        C178.093,41.588,177.891,41.512,177.958,41.111z"/>
                    </g>
                    <g className="logo-lines">
                      <rect x="31.667" y="83.166" fillRule="evenodd" clipRule="evenodd" fill="#ececec" width="162" height="2"/>
                      <rect x="30.667" y="106.166" fillRule="evenodd" clipRule="evenodd" fill="#ececec" width="162" height="2"/>
                    </g>
                    <g className="logo-engineering">
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M58.133,103.82c-0.97,0-1.828,0.051-2.673-0.03
                        c-0.265-0.025-0.598-0.36-0.726-0.634c-1.226-2.626-2.416-5.269-3.613-7.909c-0.896-1.98-1.788-3.963-2.685-5.943
                        c-0.063-0.139-0.165-0.262-0.249-0.392c-0.075,0.004-0.15,0.007-0.225,0.011c-0.416,4.922-0.832,9.845-1.252,14.83
                        c-0.613,0-1.155,0-1.839,0c0.482-5.577,0.964-11.139,1.456-16.828c0.969,0,1.885-0.042,2.792,0.029
                        c0.229,0.018,0.515,0.354,0.632,0.61c1.981,4.345,3.94,8.701,5.905,13.052c0.186,0.409,0.39,0.81,0.584,1.215
                        c0.078-0.011,0.155-0.021,0.232-0.032c0.405-4.901,0.81-9.802,1.222-14.79c0.611,0,1.206,0,1.907,0
                        C59.114,92.596,58.63,98.131,58.133,103.82z"/>
                      <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M174.323,101.743c0.407-4.901,0.813-9.803,1.224-14.76
                        c0.604,0,1.149,0,1.899,0c-0.486,5.582-0.969,11.138-1.464,16.833c-0.96,0-1.874,0.043-2.78-0.03
                        c-0.227-0.019-0.498-0.376-0.619-0.638c-1.219-2.628-2.411-5.269-3.607-7.906c-0.898-1.979-1.792-3.959-2.692-5.937
                      c-0.063-0.14-0.168-0.26-0.253-0.389c-0.074,0.006-0.148,0.013-0.223,0.019c-0.415,4.923-0.829,9.845-1.248,14.825
                      c-0.619,0-1.164,0-1.845,0c0.483-5.58,0.965-11.135,1.459-16.827c0.995,0,1.938-0.034,2.876,0.028
                      c0.199,0.013,0.442,0.348,0.552,0.587c1.989,4.376,3.96,8.76,5.938,13.141c0.168,0.373,0.355,0.736,0.533,1.104
                      C174.157,101.775,174.241,101.759,174.323,101.743z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M93.536,88.918c-0.411,4.932-0.82,9.863-1.235,14.845
                      c-0.638,0-1.179,0-1.852,0c0.487-5.604,0.97-11.164,1.463-16.834c1,0,1.916-0.035,2.824,0.026c0.206,0.014,0.465,0.324,0.571,0.556
                      c1.723,3.774,3.424,7.559,5.135,11.339c0.454,1.002,0.922,1.999,1.384,2.998c0.078-0.012,0.155-0.025,0.233-0.037
                      c0.404-4.935,0.809-9.87,1.216-14.842c0.653,0,1.198,0,1.905,0c-0.489,5.619-0.971,11.177-1.464,16.85
                      c-0.971,0-1.856,0.052-2.728-0.032c-0.254-0.024-0.561-0.395-0.69-0.677c-1.91-4.159-3.794-8.331-5.687-12.499
                      c-0.265-0.583-0.548-1.159-0.823-1.739C93.704,88.887,93.62,88.903,93.536,88.918z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M144.165,97.103c1.129,2.24,2.208,4.383,3.383,6.715
                      c-0.713,0-1.297,0.075-1.846-0.031c-0.273-0.053-0.568-0.397-0.705-0.681c-0.868-1.808-1.695-3.636-2.535-5.458
                      c-1.311,0-2.585,0-3.955,0c-0.173,2.044-0.342,4.04-0.517,6.103c-0.588,0-1.133,0-1.88,0c0.482-5.557,0.965-11.116,1.452-16.72
                      c0.269-0.042,0.489-0.104,0.708-0.104c1.549-0.005,3.107-0.096,4.645,0.042c1.023,0.091,2.107,0.346,3.004,0.827
                      c1.748,0.94,2.336,3.192,1.633,5.418c-0.467,1.476-1.322,2.637-2.667,3.426C144.661,96.771,144.448,96.92,144.165,97.103z
                      M138.666,96.08c1.488-0.085,2.778-0.05,4.028-0.255c2.377-0.389,3.894-3.291,2.959-5.52c-0.352-0.837-0.975-1.472-1.885-1.58
                      c-1.453-0.173-2.92-0.216-4.457-0.318C139.09,91.026,138.887,93.444,138.666,96.08z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M193.614,87.358c-0.037,0.572-0.066,1.022-0.102,1.53
                      c-1.222-0.147-2.354-0.33-3.493-0.407c-0.783-0.053-1.587-0.033-2.362,0.084c-1.883,0.284-3.332,1.27-4.141,3.01
                      c-1.192,2.567-1.511,5.245-0.626,7.975c0.505,1.562,1.702,2.364,3.288,2.639c1.457,0.252,2.889,0.115,4.309-0.257
                      c0.494-0.129,0.775-0.366,0.803-0.943c0.066-1.338,0.205-2.672,0.323-4.113c-1.42,0-2.725,0-4.142,0
                      c0.037-0.576,0.068-1.048,0.102-1.566c1.946,0,3.811,0,5.773,0c0,0.369,0.025,0.718-0.004,1.063
                      c-0.171,1.971-0.337,3.942-0.553,5.908c-0.032,0.299-0.249,0.737-0.488,0.828c-2.52,0.961-5.113,1.321-7.748,0.576
                      c-2.135-0.603-3.422-2.125-3.836-4.249c-0.596-3.052-0.346-6.054,1.158-8.84c1.078-1.996,2.779-3.25,5.012-3.674
                      C189.122,86.497,191.341,86.656,193.614,87.358z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M69.721,95.307c1.995,0,3.858,0,5.84,0
                      c-0.133,1.72-0.259,3.383-0.392,5.046c-0.052,0.656-0.073,1.321-0.205,1.962c-0.062,0.302-0.293,0.712-0.548,0.81
                      c-2.452,0.938-4.983,1.272-7.555,0.607c-1.923-0.498-3.277-1.738-3.803-3.666c-0.941-3.453-0.597-6.803,1.244-9.906
                      c1.101-1.856,2.83-2.897,4.944-3.29c2.111-0.392,4.974-0.132,6.564,0.617c-0.057,0.422-0.117,0.87-0.188,1.398
                      c-1.098-0.136-2.158-0.303-3.226-0.388c-0.717-0.058-1.448-0.048-2.165,0.018c-2.182,0.198-3.807,1.278-4.678,3.294
                      c-1.061,2.455-1.383,5.013-0.568,7.612c0.514,1.634,1.722,2.505,3.398,2.776c1.459,0.236,2.891,0.112,4.302-0.289
                      c0.376-0.106,0.729-0.233,0.757-0.764c0.072-1.377,0.199-2.751,0.314-4.264c-1.389,0-2.717,0-4.122,0
                      C69.667,96.297,69.692,95.849,69.721,95.307z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M119.797,86.992c-0.068,0.605-0.126,1.12-0.193,1.717
                      c-2.728,0-5.386,0-8.136,0c-0.154,1.874-0.301,3.661-0.458,5.558c2.29,0,4.484,0,6.806,0c-0.087,0.618-0.158,1.12-0.243,1.721
                      c-2.225,0-4.417,0-6.727,0c-0.175,2.037-0.342,3.983-0.52,6.055c2.748,0,5.435,0,8.239,0c-0.058,0.64-0.105,1.15-0.157,1.719
                      c-3.365,0-6.679,0-10.121,0c0.486-5.591,0.968-11.148,1.456-16.77C113.091,86.992,116.368,86.992,119.797,86.992z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M123.658,86.968c3.385,0,6.637,0,9.944,0c0,0.556,0,1.063,0,1.698
                      c-2.744,0-5.427,0-8.19,0c-0.181,1.905-0.349,3.692-0.53,5.601c2.312,0,4.535,0,6.894,0c-0.129,0.662-0.232,1.189-0.347,1.777
                      c-2.24,0-4.406,0-6.655,0c-0.187,1.991-0.367,3.912-0.562,5.985c2.767,0,5.447,0,8.235,0c-0.033,0.655-0.058,1.161-0.086,1.729
                      c-3.393,0-6.705,0-10.167,0C122.681,98.169,123.166,92.612,123.658,86.968z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M42.452,86.966c-0.06,0.622-0.11,1.133-0.168,1.732
                      c-2.737,0-5.393,0-8.148,0c-0.158,1.875-0.31,3.662-0.471,5.56c2.285,0,4.478,0,6.812,0c-0.102,0.645-0.185,1.169-0.277,1.759
                      c-2.242,0-4.408,0-6.696,0c-0.168,2.014-0.331,3.959-0.504,6.026c2.79,0,5.442,0,8.208,0c-0.028,0.619-0.05,1.123-0.076,1.702
                      c-3.422,0-6.756,0-10.175,0c0.491-5.622,0.978-11.183,1.466-16.778C35.799,86.966,39.05,86.966,42.452,86.966z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M82.554,88.548c-0.961,0-1.806,0-2.771,0
                      c0.031-0.559,0.057-1.026,0.087-1.559c2.564,0,5.047,0,7.636,0c-0.039,0.521-0.075,0.992-0.115,1.531c-0.967,0-1.848,0-2.85,0
                      c-0.404,4.564-0.803,9.067-1.212,13.687c0.976,0,1.851,0,2.765,0c0,0.554,0,1,0,1.531c-2.562,0-5.072,0-7.679,0
                      c0.051-0.516,0.097-0.988,0.149-1.522c0.931,0,1.782,0,2.77,0C81.741,97.654,82.143,93.149,82.554,88.548z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#ececec" d="M155.515,102.193c0.991,0,1.864,0,2.837,0
                      c-0.052,0.596-0.092,1.068-0.137,1.582c-2.545,0-5.006,0-7.527,0c0-0.495,0-0.964,0-1.561c0.968,0,1.871,0,2.836,0
                      c0.41-4.604,0.81-9.086,1.217-13.661c-0.934,0-1.812,0-2.805,0c0.045-0.564,0.082-1.034,0.124-1.566c2.575,0,5.091,0,7.688,0
                      c-0.075,0.525-0.14,0.977-0.218,1.532c-0.915,0-1.822,0-2.797,0C156.325,93.102,155.926,97.585,155.515,102.193z"/>
                  </g>
                  </svg>
                  </Link>

                  <DesktopNav serviceEngineering={serviceEngineering} servicePrecision={servicePrecision} fleet={fleet} handleMouseEnter={this.handleMouseEnter} handleMouseLeave={this.handleMouseLeave} />

                  <div className="header__icon_container">
                    <a href="tel:01995238096" className="header__menu-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path className="phone-icon" fill="#ececec" d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/></svg>
                    </a>

                    <button id="header__menu-button" className={`header__menu-icon toggle ${mobileMenuButtonChange}`} onClick={this.toggleMenu}>
                      <div className="header__menu-icon__inner">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </button>
                  </div>
              </div>

              <div className={`header__container-mobile toggle-content ${mobileMenuShow}`}>
                <MobileNav serviceEngineering={serviceEngineering} servicePrecision={servicePrecision} fleet={fleet} />
              </div>

            </header>
          )
        }
      }
    />
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
