import React from "react"
import { Link } from "gatsby"

import "./mobile-nav.scss"

class MobileNav extends React.Component {

    state = {
        showMenu: false,
        showServiceMobileDropdown: false,
        showFleetMobileDropdown: false,
        rotateArrow: false
    }

    toggleServiceMobileDropdownMenu = () => {
        this.setState({
            showServiceMobileDropdown: !this.state.showServiceMobileDropdown,
            rotateServiceArrow: !this.state.rotateServiceArrow
        });
    }

    toggleFleetMobileDropdownMenu = () => {
        this.setState({
            showFleetMobileDropdown: !this.state.showFleetMobileDropdown,
            rotateFleetArrow: !this.state.rotateFleetArrow
        });
    }

    render() {
        const {
            serviceEngineering,
            servicePrecision
        } = this.props;
        const serviceMobileMenuDropdownShow = this.state.showServiceMobileDropdown ? 'is-visible' : '';
        const rotateServiceArrow = this.state.rotateServiceArrow ? 'rotated' : '';
        // const fleetMobileMenuDropdownShow = this.state.showFleetMobileDropdown ? 'is-visible' : '';
        // const rotateFleetArrow = this.state.rotateFleetArrow ? 'rotated' : '';

        return(
            <nav className="header__navigation-mobile" role="navigation" aria-label="Mobile navigation">
                <ul className="header__navigation-list-mobile">
                    <li className="header__navigation-item-mobile">
                        <Link to="/" onClick={this.props.toggleMenu} activeClassName="active-link-mobile">
                            Home
                        </Link>
                        <span className="active-indicator"></span>
                    </li>
                    <li className="header__navigation-item-mobile">
                        <Link to="/services" onClick={this.props.toggleMenu} activeClassName="active-link-mobile">
                            Services
                        </Link>
                        <svg onClick={this.toggleServiceMobileDropdownMenu} className={`global_header__mobile_child-menu-toggle__arrow ${rotateServiceArrow}`}  x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24"><path d="M8.12,9.29L12,13.17l3.88-3.88c0.39-0.39,1.02-0.39,1.41,0l0,0c0.39,0.39,0.39,1.02,0,1.41l-4.59,4.59 c-0.39,0.39-1.02,0.39-1.41,0l-4.59-4.59c-0.39-0.39-0.39-1.02,0-1.41l0,0C7.09,8.91,7.73,8.9,8.12,9.29z"></path></svg>
                        <span className="active-indicator"></span>
                    </li>
                    <div className={`dropdown_content__mobile toggle-content ${serviceMobileMenuDropdownShow}`}>
                        <p className="header__navigation-list-mobile-title">CNR Engineering</p>
                        <ul>
                            {serviceEngineering &&
                            serviceEngineering.map(({ node: serviceEngineering }) => (
                                serviceEngineering.frontmatter.displayonsite &&
                                <li key={serviceEngineering.id}>
                                    {
                                        serviceEngineering.frontmatter.title === "Marked Structural Steel"
                                        ?
                                            <Link to={serviceEngineering.fields.slug}>
                                                <svg style={{marginRight: 10, marginTop: "-5px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 280 200" fillRule="evenodd" clipRule="evenodd"><path d="M110 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 000 140 70 70 0 0010-.5zM280 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 00-68.38 55H260v30h-58.38A70 70 0 00270 170a70 70 0 0010-.5z"/></svg>{serviceEngineering.frontmatter.title}
                                            </Link>
                                        :
                                            <Link to={serviceEngineering.fields.slug}>
                                                {serviceEngineering.frontmatter.title}
                                            </Link>
                                    }
                                </li>
                            ))}
                        </ul>
                        <p className="header__navigation-list-mobile-title">CNR Precision</p>
                        <ul>
                            {servicePrecision &&
                            servicePrecision.map(({ node: servicePrecision }) => (
                                servicePrecision.frontmatter.displayonsite &&
                                <li key={servicePrecision.id}>
                                    <Link to={servicePrecision.fields.slug}>
                                        {servicePrecision.frontmatter.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <li className="header__navigation-item-mobile">
                        <Link to="/our-fleet/" onClick={this.props.toggleMenu} activeClassName="active-link-mobile">
                            Our Fleet
                        </Link>
                        <span className="active-indicator"></span>
                    </li>
                    <li className="header__navigation-item-mobile">
                        <Link to="/workshop-and-equipment/" onClick={this.props.toggleMenu} activeClassName="active-link-mobile">
                            Workshop & Equipment
                        </Link>
                        <span className="active-indicator"></span>
                    </li>
                    <li className="header__navigation-item-mobile">
                        <Link to="/about/" onClick={this.props.toggleMenu} activeClassName="active-link-mobile">
                            About
                        </Link>
                        <span className="active-indicator"></span>
                    </li>
                    <li className="header__navigation-item-mobile">
                        <Link to="/contact/" onClick={this.props.toggleMenu} activeClassName="active-link-mobile">
                            Contact
                        </Link>
                        <span className="active-indicator"></span>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default MobileNav
